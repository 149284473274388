<!-- 
    页面 无权限
-->
<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="../../assets/images/pages/not-authorized.png"
        alt="graphic-not-authorized"
        class="mx-auto mb-4"
      />
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">
        <!-- You are not authorized! -->
        无权限！
      </h1>
      <p class="sm:mx-0 mx-4 sm:mb-12 mb-8 d-theme-text-inverse">
        请联系管理员！管理员电话: 00-00000000
      </p>
      <vs-button size="large" @click="logout">重新登陆</vs-button>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      logout() {
        const payload = {
          router: this.$router
        };
        this.$store.dispatch("auth/loginOut", payload);
      }
    }
  }
</script>